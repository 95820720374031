const authors = {
  andyfleming: {
    name: 'Andy Fleming',
    title: 'Principal Software Architect',
    avatarUrl: 'https://avatars3.githubusercontent.com/u/721038?s=400&u=cc90e49e5f92665375a4c4bcd26bab8a455390fb&v=4',
  },
  richardclayton: {
    name: 'Richard Clayton',
    title: 'Senior Back-end Engineering',
    avatarUrl: 'https://silvrback.s3.amazonaws.com/uploads/c23dbf97-629f-4c6b-817f-7ee62bdae2ce/headshot_large.jpg',
  }
}

export default authors
