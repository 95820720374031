import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

import { rhythm } from '../utils/typography'

function Hiring(props) {
  const baseWrapperStyles = {
    display: `flex`,
    marginBottom: rhythm(2.5),
  }
  const wrapperStyles = props.styleAsBanner ? {
    ...baseWrapperStyles,
    // background: '#f0f0f0',
    padding: '20px 15px 15px 15px',
    border: 'solid 1px #eee',
    borderLeft: 'none',
    borderRight: 'none',
  } : baseWrapperStyles

  return (
    <StaticQuery
      query={hiringQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata
        return (
          <div
            style={wrapperStyles}
          >
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={author}
              style={{
                marginRight: rhythm(1 / 2),
                marginBottom: 0,
                minWidth: 50,
                borderRadius: `100%`,
              }}
            />
            <p style={{marginBottom: 0}}>
              <strong>We're Hiring!</strong><br />
             Check out <a href="https://peachjar.com/careers.html" target="_blank">our careers page</a> for more info!
              {/*{` `}*/}
              {/*<a href={`https://twitter.com/${social.twitter}`}>*/}
                {/*You should follow us on Twitter*/}
              {/*</a>*/}
            </p>
          </div>
        )
      }}
    />
  )
}

const hiringQuery = graphql`
  query HiringQuery {
    avatar: file(absolutePath: { regex: "/peachjar-avatar.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`

export default Hiring
