import * as React from 'react'

const Author = ({data}) => {
  if (!data) {
    throw new Error('Missing author data')
  }

  return (
    <div>
      <img src={data.avatarUrl} width={100} height={100} style={{float: 'left', marginBottom: 0, marginRight: 16}} alt={data.name}/>
      <h4 style={{margin: 0}}>Author</h4>
      <h2 style={{margin: 0}}>{data.name}</h2>
      {data.title}
      <div style={{clear: 'both'}}></div>
    </div>
  )
}

export default Author
